
.button {
    display: inline-block;
    padding: 5px 10px;
    font-size: 20px;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    outline: none;
    color: #fff;
    background-color: #44bcda;
    border: none;
    border-radius: 15px;
    box-shadow: 0 7px rgb(216, 214, 214);
    margin-left:20px
  }
  
  .button:hover {background-color: #3e8e41}
  
  .button:active {
    background-color: #3e8e41;
    box-shadow: 0 3px #666;
    transform: translateY(4px);
  }



  .quotation_button {
    display: flex;
    flex-wrap: nowrap;
   
  }
  
  .quotation_button > div {
   
    width: 45%;
    min-width:350px;
    margin: 10px;
    text-align: center;
    line-height: 75px;
    font-size: 30px;
    height:20px
  }










table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }
  
  td, th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }
  
  tr:nth-child(even) {
    background-color: #dddddd;
  }



  